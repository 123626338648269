import { useState } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share';
import Button from '@/components/ui/Button';
import CopyToClipboard from '@/components/ui/CopyToClipboard';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import Icon from '@/components/ui/Icon';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const Share = ({
  title,
  url,
  className,
  trigger,
  triggerVariant = 'outline-primary',
  triggerClasses,
  size
}) => {
  const [open, setOpen] = useState(false);
  const t = useTranslations();
  const classes = cn({
    share: true,
    [className]: className
  });
  const shareBtnClasses = cn({
    'flex flex-col items-center gap-2 p-2 justify-center rounded-md hoverable': true
  });
  return <div className={classes} data-sentry-component="Share" data-sentry-source-file="Share.jsx">
      {trigger ? <div onClick={() => setOpen(true)}>{trigger}</div> : <Button size={size} className={triggerClasses ?? null} variant={triggerVariant} icon="Share" label={t('common.actions.share')} onClick={() => setOpen(true)} />}

      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="Share.jsx">
        <DialogContent size="md" data-sentry-element="DialogContent" data-sentry-source-file="Share.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="Share.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="Share.jsx">{t('common.actions.share')}</DialogTitle>
          </DialogHeader>

          <div className="flex flex-wrap gap-2 share-buttons">
            <FacebookShareButton resetButtonStyle={false} url={url} quote={title} className={shareBtnClasses} data-sentry-element="FacebookShareButton" data-sentry-source-file="Share.jsx">
              <Icon name="Facebook" size="lg" type="brand" data-sentry-element="Icon" data-sentry-source-file="Share.jsx" />
              <span className="button-label">
                Facebook
              </span>
            </FacebookShareButton>

            <TwitterShareButton resetButtonStyle={false} url={url} title={title} className={shareBtnClasses} data-sentry-element="TwitterShareButton" data-sentry-source-file="Share.jsx">
              <Icon name="Twitter" size="lg" type="brand" data-sentry-element="Icon" data-sentry-source-file="Share.jsx" />
              <span className="button-label">
                Twitter
              </span>
            </TwitterShareButton>

            <LinkedinShareButton resetButtonStyle={false} url={url} title={title} className={shareBtnClasses} data-sentry-element="LinkedinShareButton" data-sentry-source-file="Share.jsx">
              <Icon name="LinkedIn" size="lg" type="brand" data-sentry-element="Icon" data-sentry-source-file="Share.jsx" />
              <span className="button-label">
                LinkedIn
              </span>
            </LinkedinShareButton>

            <TelegramShareButton resetButtonStyle={false} url={url} title={title} className={shareBtnClasses} data-sentry-element="TelegramShareButton" data-sentry-source-file="Share.jsx">
              <Icon name="Telegram" size="lg" type="brand" data-sentry-element="Icon" data-sentry-source-file="Share.jsx" />
              <span className="button-label">
                Telegram
              </span>
            </TelegramShareButton>

            <WhatsappShareButton resetButtonStyle={false} url={url} title={title} className={shareBtnClasses} data-sentry-element="WhatsappShareButton" data-sentry-source-file="Share.jsx">
              <Icon name="Whatsapp" size="lg" type="brand" data-sentry-element="Icon" data-sentry-source-file="Share.jsx" />
              <span className="button-label">
                WhatsApp
              </span>
            </WhatsappShareButton>

            <CopyToClipboard variant="ghost" className={shareBtnClasses} label={t('common.actions.copy')} text={url} data-sentry-element="CopyToClipboard" data-sentry-source-file="Share.jsx" />
          </div>
        </DialogContent>
      </Dialog>
    </div>;
};
export default Share;