import { Children, cloneElement } from 'react';
import { cn } from '@/utils';
const Accordion = ({
  children,
  className
}) => {
  const classes = cn({
    'accordion-wrap': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="Accordion" data-sentry-source-file="Accordion.jsx">
      {Children.map(children, child => {
      return cloneElement(child);
    })}
    </div>;
};
export default Accordion;