'use client';

import Button from '@/components/ui/Button';
import Info from '@/components/ui/Info';
import { SectionHeader } from '@/components/ui/Section';
import { ROUTES } from '@/data';
import countries from '@/data/countries.json';
import { useSession, useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { getBillingInfo } from '@/utils';
export default function BillingView() {
  const {
    data: session
  } = useSession();
  const {
    user
  } = session;
  const router = useRouter();
  const t = useTranslations();
  const billingInfo = getBillingInfo(user?.billingInfo);
  const isItaly = billingInfo.country === 'IT';
  let countryName = '';
  if (billingInfo.country) {
    const countryObj = countries.find(country => country.value === billingInfo.country);
    if (countryObj) {
      countryName = countryObj.label;
    }
  }
  return <>
      <SectionHeader sticky title={t('user.actions.edit_billing')} icon="ReceiptText" actions={<>
            <Button className="overview-link" variant="primary" icon="Pencil" onClick={() => router.push(ROUTES.BILLING_EDIT)}>
              {t('user.actions.edit_billing')}
            </Button>
          </>} data-sentry-element="SectionHeader" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.firstName.label')} value={billingInfo.firstName} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.lastName.label')} value={billingInfo.lastName} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.country.label', {
      count: 1
    })} value={countryName} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.address.label')} value={billingInfo.address} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.city.label')} value={billingInfo.city} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.zipCode.label')} value={billingInfo.zipCode} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      <Info label={t('field.provinceState.label')} value={billingInfo.provinceState} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      {isItaly && <Info label="Codice Fiscale" value={billingInfo.codiceFiscale} />}

      <Info label="Email" value={billingInfo.email} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      {billingInfo.isVatCompany && <>
          <Info label={t('field.companyName.label')} value={billingInfo.companyName} />
          <Info label={t('field.vatNumber.label')} value={billingInfo.vatNumber} />
        </>}

      <Info label={t('field.phone.label')} value={billingInfo.phone} data-sentry-element="Info" data-sentry-source-file="BillingView.jsx" />

      {billingInfo.isVatCompany && isItaly && <>
          <Info label="Codice Univoco" value={billingInfo.codiceUnivoco} />
          <Info label="PEC" value={billingInfo.pec} />
        </>}
    </>;
}