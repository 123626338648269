'use client';

import { useEffect, useState } from 'react';
import Icon from '@/components/ui/Icon';
import { usePreferencesStore } from '@/store';
import { cn } from '@/utils';
const AccordionItem = ({
  children,
  label,
  icon,
  iconType,
  className,
  headerContent,
  headerClasses,
  contentClasses,
  defaultOpen = false,
  id
}) => {
  const [open, setOpen] = useState();
  const {
    closedItems,
    openItem,
    closeItem
  } = usePreferencesStore();
  useEffect(() => {
    const closedPreference = closedItems.includes(id);
    setOpen(!closedPreference ?? defaultOpen);
    if (id && defaultOpen && !closedItems.includes(id)) {
      openItem(id);
    }
  }, [defaultOpen, id]);
  const handleAccordionItemClick = () => {
    setOpen(!open);
    if (id) {
      open ? closeItem(id) : openItem(id);
    }
  };
  const classes = cn({
    'accordion': true,
    [className]: className
  });
  const headerCn = cn({
    'accordion-header': true,
    [headerClasses]: headerClasses
  });
  const contentCn = cn({
    'accordion-body': true,
    [contentClasses]: contentClasses,
    'open': open
  });
  return <div className={classes} data-sentry-component="AccordionItem" data-sentry-source-file="AccordionItem.jsx">
      <header className={headerCn} onClick={() => handleAccordionItemClick(label)}>
        <div className="flex flex-wrap items-center gap-2">
          <h5 className="flex items-center gap-3">
            {icon ? <Icon name={icon} type={iconType} /> : ''}
            {label}
          </h5>

          {headerContent && headerContent}
        </div>

        <Icon name={open ? 'ChevronUp' : 'ChevronDown'} className="ml-3" data-sentry-element="Icon" data-sentry-source-file="AccordionItem.jsx" />
      </header>

      <div className={contentCn}>
        {open && children}
      </div>
    </div>;
};
export default AccordionItem;