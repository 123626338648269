'use client';

import { useState } from 'react';
import PaymentCalculator from './PaymentCalculator';
import PaymentSummary from './PaymentSummary';
import StripeGateway from './StripeGateway';
import { BillingEdit } from '@/components/Billing';
import Portal from '@/components/ui/Portal';
import { CREDIT_DISCOUNT_PERCENTAGE } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useDiscountStore } from '@/store';
import { cn, getBillingInfo } from '@/utils';
export default function PaymentGateway({
  extra,
  onClose,
  autoApply = true,
  mode,
  monthly,
  plan,
  planName,
  price,
  payload,
  contract = false,
  creditsDiscount = CREDIT_DISCOUNT_PERCENTAGE,
  disableAffiliate,
  disableCredits,
  disableCoupon,
  maxCredits,
  className,
  planKey,
  VATBreakdown = false
}) {
  const [paymentPrice, setPaymentPrice] = useState(price);
  const [affiliateDiscount, setAffiliateDiscount] = useState(0);
  const [creditsScoreUsed, setCreditsScoreUsed] = useState(0);
  const [isTermsAgreed, setTermsAgreed] = useState(false);
  const [isContractAgreed, setContractAgreed] = useState(false);
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const t = useTranslations();
  const {
    discountAmount
  } = useDiscountStore();
  const handleUseCredits = (price, creditsUsed, affiliateDiscount) => {
    setPaymentPrice(price);
    setCreditsScoreUsed(creditsUsed);
    setAffiliateDiscount(affiliateDiscount);
  };
  if (!user?.billingInfo) {
    return <BillingEdit billingInfo={getBillingInfo(user.billingInfo)} plan={plan} planKey={planKey} />;
  }
  const classes = cn({
    payment: true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PaymentGateway" data-sentry-source-file="PaymentGateway.jsx">
      <PaymentCalculator autoApply={autoApply} billing={monthly ? 'monthly' : 'one-time'} price={price} disableCredits={disableCredits} disableAffiliate={disableAffiliate} disableCoupon={disableCoupon} creditsDiscount={creditsDiscount} maxCredits={maxCredits} planName={planName} onChange={(price, creditsUsed, affiliateDiscount) => handleUseCredits(price, creditsUsed, affiliateDiscount)} data-sentry-element="PaymentCalculator" data-sentry-source-file="PaymentGateway.jsx" />

      {paymentPrice > 0 && <StripeGateway amount={paymentPrice} creditsScoreUsed={creditsScoreUsed} disabled={!isTermsAgreed || contract && !isContractAgreed} mode={mode} payload={payload} profile={user} plan={plan} planName={planName} extra={extra} />}

      <Portal id="before-pay" data-sentry-element="Portal" data-sentry-source-file="PaymentGateway.jsx">
        <PaymentSummary className="mt-4" affiliateDiscount={affiliateDiscount} discountCodeAmount={discountAmount} disableAffiliate={disableAffiliate} disableCoupon={disableCoupon} billing={monthly ? 'monthly' : 'one-time'} creditsScoreUsed={creditsScoreUsed} price={price} paymentPrice={paymentPrice} VATBreakdown={VATBreakdown} data-sentry-element="PaymentSummary" data-sentry-source-file="PaymentGateway.jsx" />

        <div className="payment-terms">
          <label className="terms-label">
            <input type="checkbox" checked={isTermsAgreed} onChange={e => setTermsAgreed(e.target.checked)} />
            {t('payment.agreement.terms_and_privacy', {
            shouldParse: true
          })}
          </label>
        </div>

        {contract && <div className="mt-5 payment-terms">
            <label className="terms-label">
              <input type="checkbox" checked={isContractAgreed} onChange={e => setContractAgreed(e.target.checked)} />
              {t('payment.agreement.contract_of_service', {
            shouldParse: true
          })}
            </label>
          </div>}
      </Portal>
    </div>;
}